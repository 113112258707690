import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
function index(props) {
  // let index = 0
  return (
    <Wrapper abierto={props.abierto} className="submenu">
      <div className="container">
        {/* desktop */}
        {props.data
          ? props.data.map((dt, key) => {
              const { title, detailsItems } = dt
              // console.log(title, detailsItems)
              return (
                <Card key={key}>
                  <h2>{title}</h2>
                  <CardGrid>
                    {detailsItems.map((dtIt, key) => {
                      const { title, img, url } = dtIt
                      // console.log(index)
                      return (
                        <Item to={url} key={key}>
                          <img src={img} alt="item-submenu" />
                          <h3>{title}</h3>
                        </Item>
                      )
                    })}
                  </CardGrid>
                </Card>
              )
            })
          : null}
          {/* responsive max-width 1000px mobile */}
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.amarillo};
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100%;
  /* para ponerlo en marcha alerta a estop hay que descomentar */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  box-shadow: inset 0 6px 10px 0 rgba(0, 0, 0, 0.1),
    0 10px 8px 0 rgba(0, 0, 0, 0.1);
    img {
    width: 100%;
    height: auto;
  }
  h2,
  h3 {
    color: ${({ theme }) => theme.colors.azul};
    font-family: "aller";
  }
  .container {
    display: grid;
    grid-template-columns: 400px 1fr;
  }
  @media (max-width: 1000px){
    position:static;
    background-color: ${({ theme }) => theme.colors.azul};
    display: flex;
    flex-direction: column;
    overflow:hidden;
    transition: max-height 0.5s ease-out;
    max-height: ${({ abierto }) => abierto ? "100%" : "0px"};
    h2,h3{
      color: ${({ theme }) => theme.colors.amarillo};
      text-align:center;
      font-size:100%;
      margin:.5rem 0;
      
  }
  .container{
    display:block;
  }
}
`
const CardGrid = styled.div`
  color: red;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  :nth-child(2) {
    justify-self: end;
  }
@media (max-width: 1000px){
  display:block;
}
`
const Card = styled.div.attrs(props => ({
  key: props.key,
}))`
  &:first-child {
    border-right: 3px solid ${({ theme }) => theme.colors.azul};
  }
  &:nth-child(2) {
    padding-left: 200px;
  }
  &:first-child ${CardGrid}, &:nth-child(2) ${CardGrid} {
    a {
      width: 200px;
    }
  }
  &:last-child {
    grid-column: 1 / 3;
    border-top: 3px solid ${({ theme }) => theme.colors.azul};
  }
  @media (max-width: 1000px) {
    /* display: none; */
    &:nth-child(2) {
      padding-left: 0;
  }
  /* anulando bordes */
  &:first-child,
  &:last-child {
    border-top:none;
    border-right: none;
  }
  }
`

const Item = styled(Link).attrs(props => ({
  key: props.key,
}))``
export default index
